<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
    >
      <img
        src="@/assets/images/pages/404.png"
        alt="graphic-404"
        class="mx-auto mb-4 max-w-full"
      />
      <h1 class="sm:mx-0 mx-4 mb-12 text-5xl d-theme-heading-color">
        404 - Product Not Found
      </h1>
      <vs-button size="large" @click="closePage">Close Page</vs-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductDetailsErrorPage",
  props: ["urlPath"],
  methods: {
    closeIframeView() {
      const windowTemp = window;
      windowTemp.webkit.messageHandlers.jsMessageHandler.postMessage(
        'close-screen'
      );
    },
    closePage() {
      if(this.urlPath){
        let owner = this.$route.path.split('/')[1]
        const userType = this.$store.state.AppActiveUser.userType
        if (userType === "superAdmin") owner = 'super-admin/store'
        this.$router.push(`/${owner}/${this.urlPath}`)
      }
      else{
        closeIframeView();
      }
    }
  }
}
</script>
