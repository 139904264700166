<template>
  <div class="related-products text-center px-6">

    <div class="related-headings mb-8 text-center">
      <h2 class="uppercase">Related Products</h2>
      <p>People also search for this items</p>
    </div>
    <swiper :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl" class="related-product-swiper px-12 py-6">
      <swiper-slide v-for="item in relatedProducts" :key="item._id" class="p-6 rounded cursor-pointer">

        <!-- Item Heading -->
        <div class="item-heading mb-4">
          <p class="text-lg font-semibold truncate">{{ item.name }}</p>
          <p class="text-sm">
            <span class="mr-2">by</span>
            <span>{{ item.supplier.name }}</span>
          </p>
        </div>

        <!-- Item Image -->
        <div class="img-container w-32 mx-auto my-base">
          <img class="responsive" :src="item.images[0].image" :alt="item.name">
        </div>

        <!-- Item Meta -->
        <div class="item-meta">
          <!--                <star-rating :show-rating="false" :rating="item.rating" :star-size="14" class="justify-center" read-only />-->
          <p class="text-lg font-medium text-primary">${{ item.price |formatDecimals }}</p>
          <p class="text-sm">Category: <span v-for="(category,index) in item.categories" :key="category._id">{{category.name}}<span v-if="index+1 !== item.categories.length">, </span></span> </p>
        </div>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>

<script>
  import 'swiper/dist/css/swiper.min.css'
  import {swiper, swiperSlide} from "vue-awesome-swiper";
  import StarRating from "vue-star-rating";

  export default {
    name: "RelatedProducts",
    props:['relatedProducts'],
    data(){
          return {
            // Related Products Swiper
            swiperOption: {
              slidesPerView: 5,
              spaceBetween: 55,
              breakpoints: {
                1600: {
                  slidesPerView: 4,
                  spaceBetween: 55,
                },
                1300: {
                  slidesPerView: 3,
                  spaceBetween: 55,
                },
                900: {
                  slidesPerView: 2,
                  spaceBetween: 55
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 55
                }
              },
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              }
            },
          }
    },
    components: {
      swiper,
      swiperSlide,
      StarRating
    },
    }
</script>
