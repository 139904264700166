<template>
    <div>
        <form autocomplete="off" @submit.prevent class="py-5">
            <h5 class="py-2 text-pd-green font-semibold" style="font-size:14px;">
              {{ productInfo.productTypeHeading }}
            </h5>
            <p class="font-12" v-if="productInfo.productTypeDescription">
              <span>{{ productInfo.productTypeDescription }}</span>
            </p>
            <div class="w-full lg:w-10/12">
                <div v-for="({ name, label, disabled, rule }) in inputs" :key="name">
                    <vs-input 
                      :success="!errors.first(rule)" 
                      val-icon-success="done" 
                      val-icon-danger="error"
                      :danger="errors.first(rule) ? true : false" 
                      :name="name" 
                      :data-vv-as="label" 
                      :label="label"
                      v-model="form[name]" 
                      class="w-full custom-field" 
                      :disabled="disabled ? true : false"
                      :danger-text="errors.first(rule)" 
                      data-vv-scope="lead" 
                      v-validate="rule ? 'required' : ''"
                    />
                </div>
                <div>
                    <p class="font-12 mt-6 mb-3">
                      Which devices are you interested in?
                    </p>
                    <div class="checkboxInput mb-6">
                        <vs-checkbox 
                          v-for="(chbx, indx) in checkbox"
                          :key="indx"
                          :vs-value="chbx.value"
                          v-model="form.interested_in">
                          {{ chbx.value }}
                        </vs-checkbox>
                    </div>
                </div>
                <vs-button class="bg-primary p-4 w-full vs-con-loading__container"
                  :disabled="isLoading || !isValidInput"
                  @click="handleSendEnquiry">
                  Send enquiry
                </vs-button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    components: {},
    name: "LeadForm",
    props: ["productInfo", "sendEnquiry", "onSave", "isLoading"],
    data() {
        return {
            isValidInput: false,
            inputs: [{
                label: "Clinic Name",
                name: "clinicName",
                disabled: true
            }, {
                label: "Clinic City",
                name: "clinicCity",
                disabled: true
            }, {
                label: "Clinic State",
                name: "clinicState",
                disabled: true
            }, {
                label: "Contact Name",
                name: "contactName",
                rule: "lead.contactName"
            }, {
                label: "Contact #",
                name: "contactNumber",
                rule: "lead.contactNumber"
            }, {
                label: "Contact email",
                name: "contactEmail",
                rule: "lead.contactEmail"
            },],
            checkbox: [],
            form: {
              clinicName: '',
              clinicCity: '',
              clinicState: '',
              contactName: '',
              contactNumber: '',
              contactEmail: '',
              interested_in: []
            }
        }
    },
    methods: {
      async handleSendEnquiry() {
        const isValid = await this.$validator.validateAll("lead");
        if (isValid) {
          this.$emit('sendEnquiry', this.form)
        }
      },
      prefillFields() {
        if (this.$store.state.clinic) {

          if(this.$store.state.clinic[0]) {
            const {
              clinicName,
              address,
              bestContactName,
              bestContactNumber,
              bestContactEmail
            } = this.$store.state.clinic[0]

            this.form = {
              clinicName: clinicName || "",
              clinicCity: address.suburb || "",
              clinicState: address.state || "",
              contactName: bestContactName || "",
              contactNumber: bestContactNumber || "",
              contactEmail: bestContactEmail || "",
              interested_in: []
            }
          }
        }
      }
    },
    watch: {
      'form': {
        deep: true,
        handler(value) {
          const isDisabled = Object.keys(value).filter(
            (k) => !value[k] || value[k] === ""
          );
          if (isDisabled.length <= 0) {
            this.isValidInput = true;
          } else {
            this.isValidInput = false;
          }
        }
      },
      onSave: function(val) {
        if (!val) this.prefillFields()
      },
      "$store.state.clinic": function() {
        this.prefillFields()
      },
      "$store.state.clinicId": function () {
        this.errors.clear();
      }
    },
    created() {
      this.prefillFields()

      if (this.productInfo) this.checkbox = this.productInfo.options
    }
}
</script>

<style>
  .custom-field label {
      display: block;
      padding: 5px 0 !important;
      font-size: 12px;
  }
  .font-12 span {
      font-size: 12px !important;
  }
  .vs-checkbox-primary .vs-checkbox{
      border: 1px solid rgb(61 201 179)!important;
      border-radius: 4px;
  }
  .text-pd-green{
    color: #3E695C;
  }
</style>

<style lang="scss">
  .checkboxInput {
    .vs {
      &-checkbox {    
        margin-top: 0.3rem;
        width: 1.5rem;
        height: 1.5rem;

        &-primary {
          display: flex;
          align-items: flex-start;
        }
      }
    }
    .con-slot-label {
      flex: 1;
      margin-left: 1rem;
    }
  }
</style>